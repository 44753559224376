
body {
    font-size: 16px;
    /* line-height: 24px; */
    font-family: 'Ubuntu Condensed', sans-serif;

    /* padding-bottom: 0px; */
}

a,
a:hover {
    text-decoration: none;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.5;
}

.social-icons {
    padding: 0;
    margin: 0;
}

ol,
ul li {
    padding: 0;
    margin: 0;
    list-style: none;
}


p {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    margin: 0;
    text-align: justify;
}


/*front-header-section CSS*/

.front-header-section {
    width: 100%;
}

.front-top-header {
    width: 100%;
    /* background: #092942; */
    background-image: linear-gradient(45deg, #092942, #058dc5, #092942);
    color: #fafafa;
    padding: 10px 0px;
}

img.logo-image {
    max-width: 275px;
}

.front-top-header .front-info-inner {
    display: block;
    margin: 0;
}

.front-top-header .front-info-inner li {
    list-style: none;
    display: inline-block;
}

.front-top-header .front-info-inner li a {
    font-size: 16px;
    font-weight: 900;
    color: #fafafa;
    margin-right: 15px;
}

.front-top-header .front-info-inner li a:hover {
    text-decoration: none !important;
}

.front-top-header .front-info-inner li a .fa {
    /* margin-right: 7px; */
    transition: .4s;
}

.front-top-header .fa-facebook:hover {
    color: #4063AA;
}

.front-top-header .fa-google-plus:hover {
    color: #DD5144;
}

.front-top-header .fa-twitter:hover {
    color: #2CAAE1;
}

.front-top-header .fa-whatsapp:hover {
    color: #2ce1a5;
}

.front-top-header .social-icons a {
    text-align: right;
    /* display: block; */
    margin: 0px;
}

.front-top-header .social-icons li {
    display: inline-block;
    list-style: none;
}

.front-top-header .social-icons li a {
    font-size: 16px;
    color: #ffffff;
    margin: 0px 0px 0px 15px;
}

.get {
    /*background-color: #0688c0;*/
    background-image: linear-gradient(45deg, #058dc5, #092942);
    color: #fafafa;
}

.get:hover {
    background: #092942;
    color: #fafafa;
}

.front-header-section .bg-light {
    background: #ffff !important;
    padding: 10px 0px;
}

.front-header-section .navbar-expand-lg .navbar-nav {
    margin-left: 15px;
}

.front-header-section .navbar-light .navbar-nav .nav-link {
    color: #506a85;
    font-size: 15px;
    text-transform: uppercase;
    font-weight: 600;
    margin: 0px 12px;
    transition: .4s;
}

.front-header-section .navbar-light .navbar-nav .active > .nav-link {
    color: tomato !important;
    font-weight: 400;
}

.front-header-section .navbar-light .navbar-nav .nav-link:focus,
.front-header-section .navbar-light .navbar-nav .nav-link:hover {
    color: #092942;
}

.front-header-section .right-search .fa {
    color: #506a85;
}


/*front-header-section end*/


/*carousel start here*/

.slider {
    width: 100%;
}


/*carousel end here*/


/*expert start here*/

.expert {
    width: 100%;
    padding-top: 75px;
    padding-bottom: 50px;
}

.pic {
    width: 620px;
    height: 233px;
    margin-top: 10px;
}

.expert h4 {
    margin-bottom: 10px;
}


/*expert end*/

.fa-check:before {
    content: "\f00c";
    color: red;
    margin-right: 7px;
    line-height: 1.5;
}


/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/

.services {
    padding: 75px 0px;
}

.services p {
    padding: 20px 0px;
}

.services .icon-box {
    padding: 50px 20px;
    margin-top: 35px;
    margin-bottom: 25px;
    text-align: center;
    border: 1px solid #ebe6e4;
    height: 385px;
    position: relative;
    background: #eaeaea;
    border-radius: 15px 2px;
}

ul.privacy-list {
    font-size: 16px;
}

.services .icon {
    position: absolute;
    top: -36px;
    left: calc(50% - 36px);
    transition: 0.2s;
    border-radius: 50%;
    border: 6px solid #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 72px;
    height: 72px;
    background: #092942;
}

.services .icon i {
    color: #fff;
    font-size: 24px;
    line-height: 0;
}

.services .title {
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 18px;
    text-transform: uppercase;
}

.services .title a {
    color: #25282b;
}


/* .services .description {
line-height: 24px;
font-size: 14px;
} */


/*--------------------------------------------------------------
# Services end
--------------------------------------------------------------*/


/*--------------------------------------------------------------
# Drains start
--------------------------------------------------------------*/

.Projects-Drains {
    padding-bottom: 75px;
    margin-top: 50px;
}

.Projects-Drains h1 {
    /* padding: 80px 0px; */
    text-align: center;
}

.Projects-Drains-p {
    padding: 20px 0px;
}

.box {
    overflow: hidden;
    position: relative;
}

.box .box-img img {
    width: 100%;
    height: auto;
}

.box .content {
    width: 100%;
    height: 100%;
    padding: 60px 30px;
    background: #0929429d;
    position: absolute;
    top: 0;
    left: -100%;
    transition: all 0.50s ease 0s;
}

.box:hover .content {
    left: 0;
}

.box .title {
    font-size: 18px;
    color: #fff;
    text-transform: capitalize;
    margin: 0 0 30px 0;
    position: relative;
    top: 40%;
}

.service-card-img-top:hover {
    filter: grayscale(.5);
}

.box .description {
    font-size: 16px;
    color: #fff;
    margin-bottom: 35px;
}

.box .read-more {
    padding: 10px 20px;
    background: #0688c0;
    color: #fff;
    text-transform: capitalize;
    position: relative;
    top: 40%;
    border-radius: 10px 2px;
}

@media only screen and (max-width: 990px) {
    .box {
        margin-bottom: 20px;
    }
}

@media only screen and (max-width: 480px) {
    .box .content {
        padding: 20px 30px;
    }
}

.project-title {
    padding: 100px 0px;
    text-align: center;
    color: #fafafa;
}


/*--------------------------------------------------------------
# Drains start end
--------------------------------------------------------------*/


/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/

.contact {
    padding: 50px 0px;
}

.contact h2 {
    padding: 10px 0px;
}

.contact p {
    padding: 10px 0px;
    font-weight: 700;
    line-height: 1.5;
    font-size: 20px;
}

.contact .email-form {
    box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
    padding: 30px;
    border-radius: 4px;
}

.contact .email-form .form-group {
    margin-bottom: 25px;
}

.contact .email-form input,
.contact .email-form textarea {
    box-shadow: none;
    font-size: 16px;
    border-radius: 4px;
}

.contact .email-form input:focus,
.contact .email-form textarea:focus {
    border-color: #111111;
}


/* .contact .email-form input {
padding: 10px 15px;
} */

.contact .email-form textarea {
    padding: 12px 15px;
}

.contact .email-form button[type="submit"] {
    background: #0688c0;
    border: 0;
    padding: 10px 32px;
    color: #fff;
    transition: 0.4s;
    border-radius: 4px;
}

.contact .email-form button[type="submit"]:hover {
    background: #092942;
}


/*--------------------------------------------------------------
# Contact end
--------------------------------------------------------------*/


/*--------------------------------------------------------------
# Residential start
--------------------------------------------------------------*/

.Residential {
    padding: 100px 0px;
}

.color {
    /* background: #092942 !important; */
    background-image: linear-gradient(45deg, #092942, #00638d, #092942);
}


/* .list-group-item+.list-group-item {
    padding-top: 15px !important;
} */


/*--------------------------------------------------------------
# Residential end
--------------------------------------------------------------*/


/*--------------------------------------------------------------
# VISIT US start
--------------------------------------------------------------*/

.visit {
    background: url("../../images/photos/HOT-MOP-RESIDENTIAL-WITH-BENCH.jpg") center top no-repeat;
    background-color: rgba(0, 0, 0, .75);
    background-blend-mode: multiply;
    padding: 80px 0px;
    background-attachment: fixed;
    /* margin-bottom: 80px; */
}

.box {
    border: 1px solid rgb(255, 255, 255);
}

.flex-shrink-0 .fas {
    font-size: 39px;
    padding: 30px;
    background: #212529;
    color: red;
}

.size {
    background: rgba(0, 0, 0, 0.5);
    height: 100px;
    text-align: center;
    padding: 15px;
    font-weight: 700;
    line-height: 1.5;
    color: #fafafa;
}


/*--------------------------------------------------------------
# VISIT US start
--------------------------------------------------------------*/


/*--------------------------------------------------------------
# footer-section start
--------------------------------------------------------------*/

.footer-section {
    background: url("../../images/shower.jpg") center top no-repeat;
    width: 100%;
    padding: 100px 0px;
    background-color: rgb(0 7 14 / 99%);
    background-blend-mode: multiply;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
}

.footer-section .widget h4 {
    color: #fff;
    margin-bottom: 15px;
    /* text-transform: uppercase; */
}

.footer-section .widget a {
    font-size: 16px;
    font-weight: 400;
    color: white;
}

.footer-section .widget a:hover {
    color: rgb(134, 172, 177);
}

.footer-below, .footer-below p, .footer-p p, .footer-below a {
    font-size: 18px;
    font-weight: 500;
    color: rgb(136, 142, 148);
}

.footer-below a:hover {
    color: #092942;
}

.schedule-image {
    height: 200px;
    object-fit: cover;
    width: 100%;
    object-position: bottom center;
}

.app-links {
    text-align: center;
}

.app-links a {
    margin-right: 20px;
}

.app-links a i {
    font-size: 36px;
}

/*--------------------------------------------------------------
# footer-section end
--------------------------------------------------------------*/

.text-theme-light {
    color: #0688c0;
    font-family: 'Philosopher', sans-serif;
}

i.fas.fa-angle-double-right {
    transform: translate(0, 2px);
}

.text-h5-title {
    font-size: 1.15rem;
}

li.list-group-item.list-group-item-action.custom-list-item {
    font-size: 1rem;
    margin-top: 5px;
    border: 1px solid #e6e5e5;
    background: rgba(0, 100, 0, .05);
    cursor: pointer;
    word-break: break-word;
}

.carousel-caption {
    bottom: 25% !important;
}

.banner-image {
    filter: blur(2px);
    -webkit-filter: blur(2px);
    background-blend-mode: multiply;
}

.parallax-1 {
    background: url("../../images/paralax/hot-mop-1.jpg") center top no-repeat;
    background-color: rgba(0, 0, 0, .75);
    background-blend-mode: multiply;
    background-attachment: fixed;
    height: 350px;
    background-position-y: center;
    max-width: 100%;
    background-size: cover;
}

.banner-h1 {
    text-shadow: 2px 2px 2px black;
}

.parallax-1-p {
    padding: 150px 0;
}

.hot-contact-area {
    background-image: linear-gradient(45deg, #092942, #058dc5, #092942);
    padding: 50px 0;
}

.about-banner {
    height: 300px;
    width: 100%;
    object-fit: cover;
    object-position: center center;
}

.about-banner-overly {
    width: 100%;
    height: 300px;
    background-color: rgba(0, 0, 0, .70);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}

.about-overly {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    color: white;
}

.about-section-img {
    width: 100%;
    height: 250px;
    object-fit: cover;
    object-position: top center;
}

.section-gap {
    margin-top: 150px;
    margin-bottom: 150px;
}

.subscribe small {
    text-align: justify;
}

.service-card {
    border: 1px solid #f0f0f0;
}

.service-card .card-header {
    border-bottom: 1px solid #f0f0f0;
    background-image: linear-gradient(45deg, rgba(9, 41, 66, .1), rgba(5, 141, 197, .2), rgba(9, 41, 66, .1));
}

.service-card .card-header a {
    color: black;
    text-transform: uppercase;
    font-family: 'Russo One', sans-serif;
}

.schedule-feature span {
    padding-left: 10px;
}

.schedule-separator {
    background-image: linear-gradient(45deg, #092942, #058dc5, #092942);
    width: 2px;
    height: 100%;
    position: absolute;
    left: -15px;
}

.submit-contact {
    background: #0688c0;
    border: 0;
    padding: 10px 32px;
    color: #fff;
    transition: 0.4s;
    border-radius: 4px;
}

.service-for input, .appointment-service-type input {
    margin-right: 5px;
}

.service-for span, .appointment-service-type span {
    margin-right: 15px;
}

.appointment-form label {
    margin-top: 35px !important;
    margin-bottom: 5px !important;
}

.service-nav {
    background: rgba(9, 41, 66, .1);
    border-radius: 2px;
    padding: 15px 0;
}

.service-nav a {
    display: block;
    color: black;
    padding: 10px 15px;
    text-transform: uppercase;
    margin: 15px 0;
}

.service-nav a:hover, .service-nav-item-active {
    background-color: rgba(9, 41, 66, .5);
}

.appointment-area {
    background-image: linear-gradient(45deg, rgba(9, 41, 66, 0.2), rgba(5, 141, 197, 0.2), rgba(9, 41, 66, 0.2));
    padding: 20px;
    border-radius: 10px 2px;
}

button.close {
    border: none;
    background: top;
    font-size: 50px;
    position: absolute;
    right: 20px;
    font-weight: 100;
}

.modal-title {
    line-height: 1;
}

.btn-check:focus + .btn, .btn:focus {
    box-shadow: none;
}

.user-button {
    padding: 1px 5px;
}

.login-registration-panel .react-tabs__tab {
    width: 50%;
    text-align: center;
}

.user_button {
    background: transparent;
    color: white;
    padding: 2px 5px;
    border: none;
    margin-left: 10px;
    box-shadow: 0 0 5px white;
    border-radius: 5px;
}

.front-end-user-menu-container {
    position: relative;
}

.front-end-user-menu {
    min-width: 150px;
    background: #28648f;
    color: black;
    position: absolute;
    z-index: 999;
    border-radius: 5px;
    right: -2px;
    padding: 10px;
    top: 39px;
    box-shadow: 0 0 5px silver;
}

.front-end-user-menu ul {
    padding-left: 0;
}

.front-end-user-menu ul li {
    width: 100%;
    border-bottom: 1px solid white;
    padding: 2px;
    text-align: center;
}

.front-end-user-menu ul li:hover {
    background: rgba(0, 0, 0, .2);
}

.front-end-user-menu ul li:last-of-type {
    border-bottom: none;
}

.front-end-user-menu ul li button {
    background: transparent;
    border: none;
    color: white;
    padding: 2px;
}

.client-area-section-gap {
    border-top: 1px solid silver;
}

.client-nav {
    background: rgba(59, 140, 194, .2);;
    padding: 20px 10px;
    border-radius: 5px;
}

.client-nav a {
    display: block;
    color: #383838;
    padding: 5px;
    text-align: center;
    /*border-bottom: 1px solid gray;*/
}

.client-nav a:last-of-type {
    border-bottom: none;
}

.client-nav a:hover {
    background-color: #3177a8;
}

.client-nav .active {
    background-color: #3177a8;
    border-radius: 5px;
    color: white;
}

.Toastify__toast--success {
    background-color: #198754 !important;
}

.Toastify__toast {
    min-height: unset !important;
    padding: 10px !important;
}

.custom-table, .custom-table th, .custom-table td, .custom-table a {
    font-size: 16px;
    color: #262626;
}

iframe {
    width: 100%;
}

.home-image {
    height: 165px;
    width: 100%;
    object-fit: cover;
}

.project-image {
    height: 345px !important;
    width: 100%;
    object-fit: cover;
}

.app-links img {
    width: 40%;
}

.front-img-service {
    width: 100%;
    height: 175px;
    object-fit: cover;
}

table {
    margin-bottom: 0;
}

button.styles_scroll-to-top__2A70v {
    right: 15px !important;
    background: #27618b !important;
    padding: 7px !important;
    color: white !important;
    bottom: 50px !important;
}


@media only screen and (max-width: 380px) {
    img.logo-image {
        max-width: 150px;
    }
}

@media only screen and (max-width: 767px) {
    .expert {
        padding-top: 50px;
    }

    .services {
        padding: 50px 0;
    }

    .Projects-Drains {
        margin-top: 0;
    }

    .Residential {
        padding: 50px 0;
    }
}

