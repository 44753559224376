.App{
    font-size: 14px;
    font-family: 'Ubuntu Condensed', sans-serif;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Russo One', sans-serif;
    text-transform: uppercase;
    color: #092942;
    padding: 0;
    margin: 0;
}

* {
    padding: 0;
    margin: 0;
}
